import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { HeaderCover } from '@/components/HeaderCover';
import { Header } from '@/components/Header';
import styles from './index.module.scss';

const Layout: FC = () => {
  return (
    <div className={styles.Layoutwrapper}>
      <Header />
      <HeaderCover />
      <Outlet />
    </div>
  );
};

export default Layout;
