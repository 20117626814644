import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import starLogo from '@/assets/images/starLogo.png';
// import bread from '@/assets/images/products/bread.svg';
import styles from './Header.module.scss';
import { NavLink, useLocation } from 'react-router-dom';
const LinkStyle = {
  color: '#fff',
};
interface HeaderProps {
  className?: string;
}

export function Header(props: HeaderProps) {
  const { className } = props;
  const location = useLocation();
  const [hiddrenAnchor, setHiddrenAnchor] = useState(false);

  useEffect(() => {
    if (location.pathname === '/partner') {
      setHiddrenAnchor(true);
    } else {
      setHiddrenAnchor(false);
    }
  }, [location?.pathname]);

  return (
    <div className={cn(styles.Header, className)}>
      <div className={styles.inner}>
        <img src={starLogo} />
        <div className={styles.right}>
          
          <NavLink to="/Claim">
            {!hiddrenAnchor && <span className='linkMenu' style={LinkStyle}>Claim</span>}
          </NavLink>
          {!hiddrenAnchor && <a href="">Products</a>}
          {!hiddrenAnchor && <a href="">Solutions</a>}
          <a href="">Partnership</a>
          <a href="https://sns.so/">Launch App</a>
        </div>

        {/* <img src={bread} alt="" className={styles.bread} /> */}
      </div>
    </div>
  );
}
