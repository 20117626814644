import { configureChains, createConfig } from 'wagmi';
import {
  goerli,
  mainnet,
  optimism,
  avalancheFuji,
  zkSync,
  zkSyncTestnet,
  base,
  linea,
  optimismGoerli,
} from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { InjectedConnector } from 'wagmi/connectors/injected';

const configureChainsConfig = configureChains(
  [mainnet, goerli, optimism, avalancheFuji, zkSync, base, linea, zkSyncTestnet, optimismGoerli],
  [publicProvider()]
);

const { chains, publicClient } = configureChainsConfig;

const client = createConfig({
  autoConnect: true,
  connectors: [new InjectedConnector({ chains, options: { name: 'MetaMask' } })],
  publicClient,
});
export { client, chains, configureChainsConfig };
