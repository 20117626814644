import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { NavLink, useLocation } from 'react-router-dom';

import styles from './Menus.module.scss';

const LinkStyle = {
  color: '#fff',
};

interface MenusProps {
  className?: string;
  onHiddrenMenus: () => void;
}

export function Menus(props: MenusProps) {
  const { className, onHiddrenMenus } = props;

  const location = useLocation();
  const [hiddrenAnchor, setHiddrenAnchor] = useState(false);

  useEffect(() => {
    if (location.pathname === '/partner') {
      setHiddrenAnchor(true);
    } else {
      setHiddrenAnchor(false);
    }
  }, [location?.pathname]);

  return (
    <div className={cn(styles.Menus, className, { [styles.hiddrenAnchor]: hiddrenAnchor })}>
      <div className={styles.menusInner}>
        <a href="https://sns.so/">
          <div
            className={styles.menusItem}
            onClick={() => {
              onHiddrenMenus();
            }}
          >
            <span>Launch App</span>
          </div>
        </a>
        <div
          className={styles.menusItem}

        >
          <NavLink to="/Claim">
            {!hiddrenAnchor && <span className='linkMenu' style={LinkStyle}>Claim</span>}
          </NavLink>
        </div>


        <a href="https://forms.gle/u82vuoVnhjAnhSif9" target={'_blank'} rel="noreferrer">
          <div
            className={styles.menusItem}
            onClick={() => {
              onHiddrenMenus();
            }}
          >
            <span>Build</span>
          </div>
        </a>
        {!hiddrenAnchor && (
          <>
            <a href="#products">
              <div
                className={styles.menusItem}
                onClick={() => {
                  onHiddrenMenus();
                }}
              >
                <span>Products</span>
              </div>
            </a>
            <a href="#solutions">
              <div
                className={styles.menusItem}
                onClick={() => {
                  onHiddrenMenus();
                }}
              >
                <span>Solutions</span>
              </div>
            </a>
          </>
        )}

        <NavLink to="/partner">
          <div
            className={styles.menusItem}
            onClick={() => {
              onHiddrenMenus();
            }}
          >
            <span>Partnership</span>
          </div>
        </NavLink>
      </div>
    </div>
  );
}
