import cn from 'classnames';
import React from 'react';

import styles from './Partner.module.scss';
import kryptos from '@/assets/images/partner/kryptos.png';
import staratified from '@/assets/images/partner/staratified.png';
import youbic from '@/assets/images/partner/youbic.png';
import lucidblue from '@/assets/images/partner/lucidblue.png';
import capital from '@/assets/images/partner/capital.png';
import ip32 from '@/assets/images/partner/ip32.png';
import pionner from '@/assets/images/partner/pionner.png';
import lancer from '@/assets/images/partner/lancer.png';
import f3 from '@/assets/images/partner/f3.png';
import okx from '@/assets/images/partner/okx.png';
import op from '@/assets/images/partner/op.png';
import republic from '@/assets/images/partner/republic.png';
import useWindowSize from '../../hooks/useWindowSize';

interface PartnerProps {
  className?: string;
}

export function Partner(props: PartnerProps) {
  const { className } = props;
  const size = useWindowSize();

  return (
    <div className={cn(styles.Partner, className)}>
      <div className={styles.title}>Investors</div>
      {size[0] > 768 ? (
        <div className={styles.row1}>
          <img alt="" src={kryptos} />
          <img alt="" src={staratified} />
          <img alt="" src={youbic} />
          <img alt="" src={lucidblue} />
          <img alt="" src={capital} />
          <img alt="" src={ip32} />
          <img alt="" src={pionner} />
          <img alt="" src={lancer} />
        </div>
      ) : (
        <>
          <div className={styles.row1}>
            <img alt="" src={kryptos} />
            <img alt="" src={staratified} />
            <img alt="" src={youbic} />
            <img alt="" src={lucidblue} />
          </div>
          <div className={styles.row1}>
            <img alt="" src={capital} />
            <img alt="" src={ip32} />
            <img alt="" src={pionner} />
            <img alt="" src={lancer} />
          </div>
        </>
      )}
      <div className={styles.desc}>
        {`& INDIVIDUALS WHO ARE THE EARLY SUPPORTERS OF
RENOWNED WEB3 COMPANIES AND CRYPTO VCS`}
      </div>
      <div className={styles.box}>
        <img alt="" src={f3} />
        <img alt="" src={okx} />
        <img alt="" src={op} />
        <img alt="" src={republic} />
      </div>
    </div>
  );
}
