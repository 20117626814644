import cn from 'classnames';
import React from 'react';
import Typewriter from 'typewriter-effect';
import styles from './Milestones.module.scss';
import planet from '@/assets/images/products/planet.svg';
// import metamask from '@/assets/images/products/metamask.svg';
import monkey from '@/assets/images/products/monkey.png';
import useWindowSize from '../../hooks/useWindowSize';
import img1 from '@/assets/images/logo/logo1.png';
import img2 from '@/assets/images/logo/logo2.png';
import img3 from '@/assets/images/logo/logo3.png';
import img4 from '@/assets/images/logo/logo4.png';
import img5 from '@/assets/images/logo/logo5.png';
import img6 from '@/assets/images/logo/logo6.png';
import img7 from '@/assets/images/logo/logo7.png';
import img8 from '@/assets/images/logo/logo8.png';
import img9 from '@/assets/images/logo/logo9.png';
import img10 from '@/assets/images/logo/logo10.png';
import img11 from '@/assets/images/logo/logo11.png';
import img12 from '@/assets/images/logo/logo12.png';
import img13 from '@/assets/images/logo/logo13.png';
import img14 from '@/assets/images/logo/logo14.png';
import img15 from '@/assets/images/logo/logo15.png';
import img16 from '@/assets/images/logo/logo16.png';
import img17 from '@/assets/images/logo/logo17.png';
import img18 from '@/assets/images/logo/logo18.png';
import img19 from '@/assets/images/logo/logo19.png';
import img20 from '@/assets/images/logo/logo20.png';
import img21 from '@/assets/images/logo/logo21.png';
import img22 from '@/assets/images/logo/logo22.png';
import img23 from '@/assets/images/logo/logo23.png';
import img24 from '@/assets/images/logo/logo24.png';
import img25 from '@/assets/images/logo/logo25.png';
import img26 from '@/assets/images/logo/logo26.png';
import img27 from '@/assets/images/logo/logo27.png';
import img28 from '@/assets/images/logo/logo28.png';
import img29 from '@/assets/images/logo/logo29.png';
import img30 from '@/assets/images/logo/logo30.png';
import { NavLink } from 'react-router-dom';

interface MilestonesProps {
  className?: string;
}

const domainSuffix = ['.iloveyou', '.zk', '.linea', '.op', '.base', '.scroll', '.zkapp', '.kroma', '.mnt', '.blast'];

export function Milestones(props: MilestonesProps) {
  const { className } = props;
  const size = useWindowSize();

  const firstCard = (
    <div className={cn(styles.cardBox, styles.domainExtensionBox)}>
      <nav>
        {size[0] > 768
          ? `Domain Extension`
          : `Domain
Extension`}
      </nav>
      <footer>
        <div className={styles.left}>{domainSuffix.length}</div>
        <button className={styles.right}>
          <Typewriter
            options={{
              loop: true,
            }}
            onInit={(typewriter) => {
              typewriter
                .typeString(domainSuffix[0])
                .pauseFor(2000)
                .deleteAll(50)
                .typeString(domainSuffix[1])
                .pauseFor(2000)
                .deleteAll(50)
                .typeString(domainSuffix[2])
                .pauseFor(2000)
                .deleteAll(50)
                .typeString(domainSuffix[3])
                .pauseFor(2000)
                .deleteAll(50)
                .typeString(domainSuffix[4])
                .pauseFor(2000)
                .deleteAll(50)
                .typeString(domainSuffix[5])
                .pauseFor(2000)
                .deleteAll(50)
                .typeString(domainSuffix[6])
                .pauseFor(2000)
                .deleteAll(50)
                .typeString(domainSuffix[7])
                .pauseFor(2000)
                .deleteAll(50)
                .typeString(domainSuffix[8])
                .pauseFor(2000)
                .deleteAll(50)
                .typeString(domainSuffix[9])
                .pauseFor(2000)
                .deleteAll(50)
                .start();
            }}
          />
        </button>
      </footer>
    </div>
  );

  const secondCard = (
    <div className={cn(styles.cardBox, styles.peggistrationBox)}>
      <nav>Domain Registration</nav>
      <footer>
        <div className={styles.left}>700,000+</div>
        <img src={planet} alt="" className={styles.planet} />
      </footer>
    </div>
  );

  const thirdCard = (
    <div className={cn(styles.cardBox, styles.walletBox)}>
      <nav>{`Unique Wallet
Address`}</nav>
      <footer>
        <div className={styles.left}>500,000+</div>
      </footer>
    </div>
  );

  const forthCard = (
    <div className={cn(styles.cardBox, styles.networkBox)}>
      <div className={styles.left}>
        <nav>{`Network
Supporting`}</nav>
        <footer>
          <div className={styles.left}>30</div>
        </footer>
      </div>
      <div className={styles.rightBox}>
        <div className={styles.right}>
          <img src={img4} alt="" />
          <img src={img3} alt="" />
          <img src={img2} alt="" />
          <img src={img8} alt="" />
          <img src={img1} alt="" />
          <img src={img24} alt="" />
          <div className={styles.row}>
            <img src={img20} alt="" />
            {/* <span className={styles.minChianText}>X1</span> */}
          </div>
          <img src={img28} alt="" />
          <img src={img25} alt="" />
          <img src={img26} alt="" />
          <img src={img5} alt="" />
          <img src={img30} alt="" />
          <img src={img16} alt="" />
          <img src={img27} alt="" />
          <img src={img29} alt="" />
          <img src={img6} alt="" />
          <img src={img7} alt="" />
          <img src={img12} alt="" />
          <img src={img21} alt="" />
          <img src={img13} alt="" />
          <img src={img11} alt="" />
          <img src={img15} alt="" />
          <img src={img9} alt="" />
          <img src={img17} alt="" />
          <img src={img18} alt="" />
          <img src={img19} alt="" />
          <img src={img22} alt="" />
          <img src={img14} alt="" />

          <div className={styles.row}>
            <img src={img23} alt="" />
            <span>zkEVM</span>
          </div>
          <img src={img10} alt="" />
        </div>
      </div>
    </div>
  );

  const fifthCard = (
    <div className={cn(styles.cardBox, styles.partnersBox)}>
      <div className={styles.left}>
        <nav>{'Partners'}</nav>
        <footer>
          {/* 合作伙伴的个数 */}
          <div className={styles.left}>300+</div>
        </footer>
      </div>
      <div className={styles.right}>
        {/* <img src={monkey} alt="" className={styles.monkey} /> */}
        <button className={styles.explore}>Explore</button>
      </div>
    </div>
  );

  return (
    <div className={cn(styles.Milestones, className)}>
      <div className={styles.title}>Milestones</div>
      {size[0] > 768 ? (
        <>
          <div className={styles.carWrapper}>
            {/* 第一张 */}
            {firstCard}
            {/* 第二张 */}
            {secondCard}
            {/* 第三张 */}
            {thirdCard}
          </div>
          <div className={styles.carWrapper}>
            {' '}
            {/* 第4张 */}
            {forthCard}
            <div className={styles.coverBox}>
              {' '}
              <NavLink to="/partner">
                <div className={styles.cover}>Explore</div>
              </NavLink>
              {fifthCard}
            </div>
            {/* 弟5 张 */}
          </div>
        </>
      ) : (
        <>
          <div className={styles.carWrapper}>
            {' '}
            {/* 第一张 */}
            {firstCard}
            {/* 第二张 */}
            {secondCard}
          </div>
          <div className={styles.carWrapper}>
            {' '}
            {/* 第三张 */}
            {thirdCard}
            {/* 弟5 张 */}
            <div className={styles.coverBox}>
              {' '}
              <NavLink to="/partner">
                <div className={styles.cover}>Explore</div>
              </NavLink>
              {fifthCard}
            </div>
          </div>
          <div className={styles.carWrapper}>
            {' '}
            {/* 第4张 */}
            {forthCard}
          </div>
        </>
      )}
      <div id="solutions"></div>
    </div>
  );
}
