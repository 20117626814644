import axios from 'axios';
import themeMP4 from '../assets/images/vv.mp4';
import themeMP4L from '../assets/images/vvv.mp4';

export function getURL() {
  return new Promise(function (resolve, reject): any {
    const params: any = {
      responseType: 'blob',
      type: 'video/mp4',
    };
    axios
      .get(themeMP4, params)
      .then((res) => {
        const blob = new Blob([res.data]);
        const url = window.URL.createObjectURL(blob);
        resolve(url);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getURLS() {
  return new Promise(function (resolve, reject): any {
    const params: any = {
      responseType: 'blob',
      type: 'video/mp4',
    };
    axios
      .get(themeMP4L, params)
      .then((res) => {
        const blob = new Blob([res.data]);
        const url = window.URL.createObjectURL(blob);
        resolve(url);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
