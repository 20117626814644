import { getURL } from '@/tool/getVideoURL';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import launchYourDomain from '@/assets/images/launchYourDomain.png';
import styles from './StarProtocolIntroducetion.module.scss';
import videoFirstPage from '@/assets/images/videoFirstPage.png';
import crs from '@/assets/images/crs.gif';

interface StarProtocolIntroducetionProps {
  className?: string;
}

export function StarProtocolIntroducetion(props: StarProtocolIntroducetionProps) {
  const { className } = props;

  const [videodata, setVideoData] = useState('');
  const [hiddren, setHiddren] = useState(false);

  useEffect(() => {
    const videos = document.getElementsByTagName('video');
    getURL()
      .then((url: any) => {
        setVideoData(url);
        setTimeout(() => {
          if (videos && videos.length > 0) {
            videos[0].play();
          }
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className={cn(styles.StarProtocolIntroducetion, className)}>
      <div className={styles.title}>Partner with Star Protocol</div>
      <div className={styles.cardWrapper}>
        <div className={styles.cardBox}>
          {/* <div className={styles.cardTitle}>Omnichain Registration and Resolving</div> */}
          {/* <div className={styles.desc}>
            {`Secured omnichain registrar controller and resolver, leveraging the trusted
cross-chain messaging protocol.`}
          </div> */}
          <div className={styles.video}>
            {/* <img
              src={crs}
              alt="launchYourDomain"
              className='crs'
            /> */}
            <video
              // width={300}
              height={288}
              autoPlay
              loop
              muted
              playsInline
              src={videodata}
              // x5-video-player-type="h5"
              poster={videoFirstPage}
            ></video>
          </div>
        </div>

        {/* 第二张 */}
        <div className={styles.cardBox2}>
          <div className={styles.cardTitle}>Blockchain Domain as a Service</div>
          <div className={styles.desc}>
            {`Partner with Star for omnichain
name services and unlock the
potential of launching new domains
together`}
            <img
              src={launchYourDomain}
              alt="launchYourDomain"
              className={cn(styles.launchYourDomain, { [styles.show]: hiddren })}
            />
            <div className={styles.btnWrapper}></div>
            <div className={styles.btn}>
              <span className={styles.text}>Launch Your Domain</span>
            </div>
            <div
              className={styles.btnCover}
              onClick={(e) => {
                e.stopPropagation();
                window.location.href = 'https://forms.gle/u82vuoVnhjAnhSif9';
              }}
              onMouseEnter={() => setHiddren(true)}
              onMouseLeave={() => setHiddren(false)}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
