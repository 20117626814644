import styled from 'styled-components';
// import arrow from "../../assets/linea/arrow.png";
import { partaner } from './list';
import footerBg from '@/assets/images/footerBg.svg';
import cn from 'classnames';
import styles from './index.module.css';
import { useReducer } from 'react';
import { Footer } from '../Footer';
// import useWindowSize from '@/hooks/useWindowSize';
console.log(partaner.length, '合作伙伴的个数');
const LineaCard = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  // background: #008DDAdb;
  position: relative;
  overflow: hidden;

  main {
    margin: 0 auto;
    padding: 0 140px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 40px;
    margin-bottom: 40px;
    gap: 38px 20px;
    box-sizing: border-box;
    position: relative;
    height: fit-content;

    @media screen and (max-width: 1198px) {
      width: 890px;
      padding: 0;
      padding-top: 40px;
    }
    @media screen and (max-width: 1180px) {
      gap: 30px 16px;
      margin-bottom: 39px;
    }

    @media screen and (max-width: 890px) {
      width: 700px;
    }
    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }
`;
const Title = styled.div`
  font-size: 64px;
  font-family: 'GT-Walsheim-Bold-Trial';
  color: #fff;
  white-space: pre-line;
  text-align: center;
  padding-top: 121px;
  margin-bottom: 40px;

  @media screen and (max-width: 1280px) {
    font-size: 5.3rem;
    padding-top: 10rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    /* padding-top: 32px; */
    margin-bottom: 32px;
    padding-top: 74px;
  }
`;
const Inner = styled.div`
  width: 1280px;
  margin: 0 auto;
  position: relative;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

const Button = styled.button`
  margin: 0 auto;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-family: 'GT-Walsheim-Bold-Trial';
  border-radius: 8px;
  background: linear-gradient(90deg, #6806e4 3.8%, #47a7ff 91.66%);
  border: none;

  cursor: pointer;

  &:hover {
    background: linear-gradient(90deg, #6806e4 3.8%, #47a7ff 91.66%);
    color: #fff;
  }

  @media screen and (max-width: 1180px) {
    font-size: 16px;
  }
  @media screen and (max-width: 550px) {
    font-size: 14px;
  }
`;

const Butn = styled.div``;

const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
// const APIButton=styled.div`
// width:100% ;
// display:flex ;
// justify-content:center ;
// margin:15px ;

// a{
//   display:block ;
//   text-decoration:none ;
//   padding:0 15px ;
//   height:40px ;
//   display:flex ;
//   align-items:center ;
//   justify-content:center ;
//   border:1px solid #fff ;
//   color:#fff ;
//   border-radius:20px ;
//   overflow:hidden ;
//   font-size:16px ;
//   font-family: 'Helvetica-Regular';
// }

// a:hover{
//   background:linear-gradient(90deg, rgba(229, 83, 93, 1), rgba(234, 234, 33, 1), rgba(80, 209, 239, 1), rgba(51, 204, 102, 1));
//   color:#000;
// }
// `
// const card2=[1,6,11,16,21,26,31,36,41,46,51,56,61,66,71,76,81,86,91,96,101,106];
const btns = [
  { id: 1, name: 'ALL', active: true },
  { id: 2, name: 'Wallet', active: false },
  { id: 3, name: 'Social', active: false },
  { id: 4, name: 'Game', active: false },
  { id: 5, name: 'NFT', active: false },
  { id: 6, name: 'Defi', active: false },
  { id: 7, name: 'Bridge', active: false },
  { id: 8, name: 'Infrastructure', active: false },
  { id: 9, name: 'Tool', active: false },
  { id: 10, name: 'DAO', active: false },
];

interface ActionState {
  type: string;
  payload: number | string;
}
export function Cards() {
  const [btnss, dispatch] = useReducer(reducer, btns);
  const [logos, LogoDispatch] = useReducer(reducer, JSON.parse(JSON.stringify(partaner)));

  function reducer(state: any, action: ActionState) {
    if (action.type === 'select') {
      const arr = JSON.parse(JSON.stringify(state));
      arr.forEach((v: any) => {
        if (v.id === action.payload) {
          v.active = true;
        } else {
          v.active = false;
        }
      });
      return arr;
    }

    if (action.type === 'logo') {
      if (typeof action.payload === 'string' && action.payload.trim().toLocaleLowerCase() === 'all') {
        return partaner;
      }
      return partaner.filter((v) => {
        if (
          typeof action.payload === 'string' &&
          v.category.trim().toLocaleLowerCase() === action.payload.trim().toLocaleLowerCase()
        ) {
          return true;
        }
        return false;
      });
    }
  }

  function onSelected(id: number, name: string) {
    dispatch({ type: 'select', payload: id });
    onChooseSameType(name);
  }

  function onChooseSameType(type: string) {
    LogoDispatch({ type: 'logo', payload: type });
  }
  return (
    <LineaCard>
      <div className={styles.PartnerWrapper}>
        <Inner>
          <Title>{`Explore the Wonder of
Star Ecosystem`}</Title>
          <div className={styles.btnWrapper}>
            <a
              href="https://docs.google.com/forms/d/1mmPeD4C580LE2dkXj90AGADUjtBX1pF3KZZYsEgU6UE"
              target="_blank"
              rel="noreferrer"
              className={styles.submit}
            >
              {/* <Button>Submit Your Projects</Button> */}
            </a>
          </div>
          <BtnContainer>
            <Butn className={styles.btns}>
              {btnss.map((v: any, index: number) => (
                <button
                  key={index}
                  className={cn({ [styles.active]: v.active })}
                  onClick={() => onSelected(v.id, v.name)}
                >
                  {v.name}
                </button>
              ))}
            </Butn>
          </BtnContainer>
          <main>
            {logos.length > 0 &&
              logos.map((v: any, index: number) => {
                return (
                  <Card
                    href={v.projecturl}
                    key={index}
                    img={v.logourl}
                    projectname={v.projectname}
                    category={v.category}
                  />
                );
              })}
          </main>
        </Inner>
        {/* {width > 890 && logos.length <= 5 ? <Footer3 /> : <Footer2 logosNumber={logos.length} />} */}
      </div>
      <div className={styles.footerBox}>
        <img alt="" src={footerBg} className={styles.footerPicture} />
        <Footer />
      </div>
    </LineaCard>
  );
}
const CardItem = styled.div`
  padding-top: 26px;
  box-sizing: border-box;
  width: 180px;
  height: 260px;
  background: #1d1d1d;
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 1);
  border-radius: 10px;
  border: 1px solid #4b4b4b;
  position: relative;
  article {
    margin-top: 20px;
    font-size: 20px;
    font-family: 'GT-Walsheim-Regular-Trial';
    font-weight: bold;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
  }
  aside {
    font-size: 16px;
    font-family: 'GT-Walsheim-Regular-Trial';
    color: #ffffff;
    line-height: 25px;
    text-align: center;
  }
  @media screen and (max-width: 1180px) {
    padding-top: 20px;
    width: 141px;
    height: 203px;
    article {
      margin-top: 16px;
      font-size: 15px;
    }
    aside {
      font-size: 13px;
    }
  }
`;

const RoundSide = styled.div`
  margin: 0 auto;
  width: 136px;
  height: 136px;
  background: #141414;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  @media screen and (max-width: 1180px) {
    width: 106px;
    height: 106px;
  }
`;

const Logo = styled.img`
  width: 110px;
  height: 110px;
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  @media screen and (max-width: 1180px) {
    width: 86px;
    height: 86px;
  }
`;
const RoundInner = styled.div`
  width: 110px;
  height: 110px;
  background: #000000;
  border-radius: 50%;
  position: relative;
  @media screen and (max-width: 1180px) {
    width: 86px;
    height: 86px;
  }

  &:hover {
    animation: jelly 0.5s;
  }
  @keyframes jelly {
    0%,
    100% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(0.9, 1.1);
    }
    50% {
      transform: scale(1.1, 0.9);
    }
    75% {
      transform: scale(0.95, 1.05);
    }
  }
`;

const Round = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  background: #4b4b4b;
  border-radius: 50%;
  right: -8px;
  top: -8px;
  @media screen and (max-width: 1180px) {
    width: 13px;
    height: 13px;
    right: -6px;
    top: -6px;
  }
`;

interface CardProps {
  img: string;
  projectname: string;
  category: string;
  href: string;
}
function Card(props: CardProps) {
  const { img, projectname, category, href } = props;
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <CardItem>
        <Round></Round>
        <RoundSide>
          <RoundInner>
            <Logo src={img} />
          </RoundInner>
        </RoundSide>
        <article>{projectname}</article>
        <aside>{category}</aside>
      </CardItem>
    </a>
  );
}
