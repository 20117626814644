import styled from 'styled-components';
import { getURLS } from '@/tool/getVideoURL';

// import arrow from "../../assets/linea/arrow.png";
import { partaner } from './list';
import footerBg from '@/assets/images/footerBg.svg';
import cn from 'classnames';
import styles from './index.module.css';
import React, { FC, useEffect, useReducer, useState } from 'react';
import { Footer } from '../Footer';
import Steps from './steps';
import videoFirstPage from '@/assets/images/videoFirstPage.png';
import Marquee from "react-fast-marquee";
import r1 from "../../assets/images/crs/Layer1.png";
import r2 from "../../assets/images/crs/Layer2.png";
import r3 from "../../assets/images/crs/Layer3.png";
import r4 from "../../assets/images/crs/Layer4.png";
import r5 from "../../assets/images/crs/Layer5.png";
import r6 from "../../assets/images/crs/Layer6.png";
import r7 from "../../assets/images/crs/Layer7.png";
import r8 from "../../assets/images/crs/Layer8.png";
import r9 from "../../assets/images/crs/Layer9.png";
import r10 from "../../assets/images/crs/Layer10.png";
import r11 from "../../assets/images/crs/Layer11.png";
import r12 from "../../assets/images/crs/Layer12.png";
import r13 from "../../assets/images/crs/Layer13.png";
import r14 from "../../assets/images/crs/Layer14.png";
import r15 from "../../assets/images/crs/Layer15.png";
import r16 from "../../assets/images/crs/Layer16.png";
import Countdown, { CountdownRenderProps } from 'react-countdown';
// import { WagmiProvider, useAccount, useConnect, useDisconnect } from "wagmi";
// import { WalletButton } from "@rainbow-me/rainbowkit";
// import { getDefaultProvider } from 'ethers';

import { useConnect } from 'wagmi'







// 定义选项卡数据的接口
interface Tab {
  label: string;
  content: JSX.Element;
}

// 定义选项卡组件的属性类型
interface TabsProps {
  tabs: Tab[];
}

const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  const [activeIndex, setActiveIndex] = useState(0);



  // 处理选项卡切换
  const handleTabClick = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <div className={styles.tabs}>
      <div className={styles.tabHead}>
        <div className={styles.tabHeader}>
          {/* 渲染选项卡标题 */}
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`${styles.tab} ${index === activeIndex ? `${styles.tabActive}` : ''}`}
              onClick={() => handleTabClick(index)}
            >
              {tab.label}
            </div>
          ))}
        </div>
      </div>
      {/* 渲染选中选项卡的内容 */}
      <div className={styles.tabContent}>{tabs[activeIndex].content}</div>
    </div>
  );
};



// import useWindowSize from '@/hooks/useWindowSize';
console.log(partaner.length, '合作伙伴的个数');
const LineaCard = styled.div`
  overflow:hidden;
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  // background: #FFFFFF52;
  position: relative;
  overflow: hidden;

  main {
    margin: 0 auto;
    padding: 0 140px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 40px;
    margin-bottom: 40px;
    gap: 38px 20px;
    box-sizing: border-box;
    position: relative;
    height: fit-content;

    @media screen and (max-width: 1198px) {
      width: 890px;
      padding: 0;
      padding-top: 40px;
    }
    @media screen and (max-width: 1180px) {
      gap: 30px 16px;
      margin-bottom: 39px;
    }

    @media screen and (max-width: 890px) {
      width: 700px;
    }
    @media screen and (max-width: 700px) {
      width: 100%;
    }
  }
`;
const Title = styled.div`
  font-size: 64px;
  font-family: 'GT-Walsheim-Bold-Trial';
  color: #fff;
  white-space: pre-line;
  text-align: center;
  padding-top: 121px;
  margin-bottom: 40px;

  @media screen and (max-width: 1280px) {
    font-size: 5.3rem;
    padding-top: 10rem;
  }

  @media screen and (max-width: 768px) {
    font-size: 32px;
    /* padding-top: 32px; */
    margin-bottom: 32px;
    padding-top: 74px;
  }
`;
const Inner = styled.div`
  width: 1280px;
  margin: 0 auto;
  position: relative;
  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const btns = [
  { id: 1, name: 'ALL', active: true },
  { id: 2, name: 'Wallet', active: false },
  { id: 3, name: 'Social', active: false },
  { id: 4, name: 'Game', active: false },
  { id: 5, name: 'NFT', active: false },
  { id: 6, name: 'Defi', active: false },
  { id: 7, name: 'Bridge', active: false },
  { id: 8, name: 'Infrastructure', active: false },
  { id: 9, name: 'Tool', active: false },
  { id: 10, name: 'DAO', active: false },
];



interface ActionState {
  type: string;
  payload: number | string;
}
export const ClaimPage: FC = () => {

  const [milliseconds, setMilliseconds] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [days, setDays] = useState(0);

  const { connectors } = useConnect();
  const { connect } = useConnect()


  useEffect(() => {
    const interval = setInterval(() => {
      // 更新时间状态，以达到流逝的效果
      setMilliseconds(prevMilliseconds => (prevMilliseconds + 1) % 1000);
      if (milliseconds === 99) {
        setSeconds(prevSeconds => (prevSeconds + 1) % 60);
        if (seconds === 59) {
          setMinutes(prevMinutes => (prevMinutes + 1) % 60);
          if (minutes === 59) {
            setHours(prevHours => (prevHours + 1) % 24);
            if (hours === 23) {
              setDays(prevDays => prevDays + 1);
            }
          }
        }
      }
    }, 1); // 每毫秒更新一次时间状态，可以根据需要调整间隔

    return () => clearInterval(interval);
  }, [milliseconds, seconds, minutes, hours, days]);


  const [videodata, setVideoData] = useState('');
  useEffect(() => {
    const videos = document.getElementsByTagName('video');
    getURLS()
      .then((url: any) => {
        setVideoData(url);
        setTimeout(() => {
          if (videos && videos.length > 0) {
            videos[0].play();
          }
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function reducer(state: any, action: ActionState) {
    if (action.type === 'select') {
      const arr = JSON.parse(JSON.stringify(state));
      arr.forEach((v: any) => {
        if (v.id === action.payload) {
          v.active = true;
        } else {
          v.active = false;
        }
      });
      return arr;
    }

    if (action.type === 'logo') {
      if (typeof action.payload === 'string' && action.payload.trim().toLocaleLowerCase() === 'all') {
        return partaner;
      }
      return partaner.filter((v) => {
        if (
          typeof action.payload === 'string' &&
          v.category.trim().toLocaleLowerCase() === action.payload.trim().toLocaleLowerCase()
        ) {
          return true;
        }
        return false;
      });
    }
  }

  // 自定义 renderer 函数来显示更小的时间单位
  const renderer = ({ total }: CountdownRenderProps) => {
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / (1000 * 60)) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return (
      <div className="countdown">
        {days} : {hours} : {minutes} : {seconds} :  {milliseconds}
      </div>
    );
  };




  const steps = [
    {
      title: '✨Welcome',
      content: <div>
        <p style={{ marginTop: '10px' }}>Reserved Wallets</p>
        <p style={{ fontSize: '50px', marginBottom: '10px' }}>610,522</p>
        <p className='stepText'>Enter the realm of the future! We aren’t just about crypto coins. </p>
        <p className='stepText'>We’re the digital candy store of Web3 NFT‘s and if that’s not enough‚ we’ve got airdrops. </p>
        <p className='stepText'>A world beyond imagination awaits you. Brace yourself‚ it’s gonna be a wild ride! </p>
        {/* <button className={styles.walletBtn}>Start</button> */}
      </div>,
    },
    // {
    //   title: '2 Connect to wallet',
    //   content: <div>
    //     <p className='stepText'>Choose your wallet, get ready to proceed with the next steps. Thank you for participating!</p>
    //     <p className='stepText'> If you encounter any issues, feel free to contact our support team.</p>
    //     <button onClick={() => connect({ connector: connectors[0] })} className={styles.walletBtn}>Connect Wallet</button>


    //   </div>,
    // },
    // {
    //   title: '3 Verify Eligibility',
    //   content: <div>
    //     <p className='stepText'>Please make sure you have read and agreed to our Airdrop rules and terms. </p>
    //     <p className='stepText'>Only participants who comply with the specified requirements are eligible to claim tokens.</p>
    //     <button className={styles.walletBtn}>Check Eligibility</button>
    //   </div>,
    // },

    // {
    //   title: '4 Claim',
    //   content: <div>
    //     <p className='stepText'>Get lost in our huge, bustling community of crypto enthusiasts. </p>
    //     <p className='stepText'>It’s the coolest place to hang out, and learn new tricks in the trade. </p>
    //     <p className='stepText'>The latest and hottest of the digital world, right at your fingertips. You don’t want to miss out‚ trust us! </p>
    //     <button className={styles.walletBtn}>Claim Your Token</button>
    //   </div>,
    // }
  ];



  // 选项卡数据
  const tabs: Tab[] = [
    { label: 'Preview', content: <Steps steps={steps} /> },
    {
      label: 'NFT', content: <div className={styles.countBox}>🪐 Stay tuned.
        {/* <Countdown
        date={Date.now() + 998.235*8574*962.3} // 设置倒计时结束的时间，这里是10秒后
        renderer={renderer} // 使用自定义的 renderer 函数
        intervalDelay={1}
      /> */}
      </div>
    },
    {
      label: 'Points', content: <div className={styles.countBox}>🌍 On the Horizon.
        {/* <Countdown
        date={Date.now() + 1250.235*8574*962.3} // 设置倒计时结束的时间，这里是10秒后
        renderer={renderer} // 使用自定义的 renderer 函数
        intervalDelay={1}
      /> */}
      </div>
    },
  ];

  // useEffect(() => {
  //   // 当组件加载时将 body 的 overflow 设为 hidden
  //   document.body.style.overflow = 'hidden';
  //   // 在 cleanup 函数中将 body 的 overflow 设为 visible
  //   return () => {
  //     document.body.style.overflow = 'auto';
  //   };
  // }, []);



  return (
    <LineaCard>
      <div className={styles.PartnerWrapper}>
        <Inner>
          <div>
            <Title className={styles.titleStep}>{`Check your airdrops & NFT`}</Title>
            <Title className={styles.titleStepTrue}>{`Check your airdrops & NFT`}</Title></div>
          <br></br>


          <main>
            <div className={styles.stepDiv}>
              <div className={styles.fakeText}>🪐 Stay tuned.</div>
              <div className={styles.fakeTextTrue}>🪐 Stay tuned.</div>

              {/* <Tabs tabs={tabs} /> */}
              {/* <button onClick={() => connect({ connector: connectors[0] })} className={styles.walletBtn}>Connect Wallet</button> */}

              {/* <WagmiConfig> */}
              {/* <button onClick={() => connect({ connector: injected() })} className={styles.walletBtn}>Connect Wallet</button> */}
              {/* </WagmiConfig> */}

              {/* <Steps steps={steps} /> */}
            </div>


          </main>
          <div className={styles.videoNft}>
            <video
              width='100%'
              // height={288}
              autoPlay
              loop
              muted
              playsInline
              src={videodata}
            // x5-video-player-type="h5"
            // poster={videoFirstPage}
            ></video>
          </div>
        </Inner>
        <Marquee speed={100} className={styles.imgBar}>
          <img className={styles.imgStep} src={r1}></img>
          <img className={styles.imgStep} src={r2}></img>
          <img className={styles.imgStep} src={r3}></img>
          <img className={styles.imgStep} src={r4}></img>
          <img className={styles.imgStep} src={r5}></img>
          <img className={styles.imgStep} src={r6}></img>
          <img className={styles.imgStep} src={r7}></img>
          <img className={styles.imgStep} src={r8}></img>
          <img className={styles.imgStep} src={r9}></img>
          <img className={styles.imgStep} src={r10}></img>
          <img className={styles.imgStep} src={r11}></img>
          <img className={styles.imgStep} src={r12}></img>
          <img className={styles.imgStep} src={r13}></img>
          <img className={styles.imgStep} src={r14}></img>
          <img className={styles.imgStep} src={r15}></img>
        </Marquee>
        {/* {width > 890 && logos.length <= 5 ? <Footer3 /> : <Footer2 logosNumber={logos.length} />} */}
      </div>

      <div className={styles.footerBox}>
        {/* <img alt="" src={footerBg} className={styles.footerPicture} /> */}

        <Footer />
      </div>
    </LineaCard>
  );
}
const CardItem = styled.div`
  padding-top: 26px;
  box-sizing: border-box;
  width: 180px;
  height: 260px;
  background: #1d1d1d;
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 1);
  border-radius: 10px;
  border: 1px solid #4b4b4b;
  position: relative;
  article {
    margin-top: 20px;
    font-size: 20px;
    font-family: 'GT-Walsheim-Regular-Trial';
    font-weight: bold;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
  }
  aside {
    font-size: 16px;
    font-family: 'GT-Walsheim-Regular-Trial';
    color: #ffffff;
    line-height: 25px;
    text-align: center;
  }
  @media screen and (max-width: 1180px) {
    padding-top: 20px;
    width: 141px;
    height: 203px;
    article {
      margin-top: 16px;
      font-size: 15px;
    }
    aside {
      font-size: 13px;
    }
  }
`;

const RoundSide = styled.div`
  margin: 0 auto;
  width: 136px;
  height: 136px;
  background: #141414;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  @media screen and (max-width: 1180px) {
    width: 106px;
    height: 106px;
  }
`;

const Logo = styled.img`
  width: 110px;
  height: 110px;
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  @media screen and (max-width: 1180px) {
    width: 86px;
    height: 86px;
  }
`;
const RoundInner = styled.div`
  width: 110px;
  height: 110px;
  background: #000000;
  border-radius: 50%;
  position: relative;
  @media screen and (max-width: 1180px) {
    width: 86px;
    height: 86px;
  }

  &:hover {
    animation: jelly 0.5s;
  }
  @keyframes jelly {
    0%,
    100% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(0.9, 1.1);
    }
    50% {
      transform: scale(1.1, 0.9);
    }
    75% {
      transform: scale(0.95, 1.05);
    }
  }
`;

const Round = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  background: #4b4b4b;
  border-radius: 50%;
  right: -8px;
  top: -8px;
  @media screen and (max-width: 1180px) {
    width: 13px;
    height: 13px;
    right: -6px;
    top: -6px;
  }
`;

interface CardProps {
  img: string;
  projectname: string;
  category: string;
  href: string;
}
function Card(props: CardProps) {
  const { img, projectname, category, href } = props;
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <CardItem>
        <Round></Round>
        <RoundSide>
          <RoundInner>
            <Logo src={img} />
          </RoundInner>
        </RoundSide>
        <article>{projectname}</article>
        <aside>{category}</aside>
      </CardItem>
    </a>
  );
}
