import { RouterProvider } from 'react-router-dom';
import { WagmiConfig } from 'wagmi';
import { ApolloProvider } from '@apollo/client';
import { client } from '@/wagmi';
import apolloClient from '@/utils/apollo';
import './index.css';
import router from '@/routes';
import { Toaster } from 'react-hot-toast';
import { resizeListener } from '@/tool/resize';
import { useEffect } from 'react';
import { Effect } from '@/components/MouseRandomColor/RandomColor';
// import { useConnect } from 'wagmi';


if (process.env.NODE_ENV === 'production') {
  console.log = () => { };
}

const canvasStyle = {
  pointerEvents: 'none', // 不阻止鼠标事件
};

export function App() {
  // const { connectors } = useConnect();
  // const { connect } = useConnect();
  useEffect(() => {
    window.addEventListener('resize', resizeListener);
    resizeListener();
  }, []);
  return (
    <>
      <ApolloProvider client={apolloClient}>
        <WagmiConfig config={client}>
          <div className={`w-full h-full myFluidBg`}>
            <Effect className="w-full h-full myFluidBg" style={canvasStyle}></Effect>
          </div>
          <div className="App">
            {/* <button onClick={() => connect({ connector: connectors[0] })} >Connect Wallet</button> */}
            <Toaster />
            <RouterProvider router={router} />
          </div>
        </WagmiConfig>
      </ApolloProvider>
    </>
  );
}
