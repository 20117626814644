import cn from 'classnames';
import React from 'react';
import themeLogo from '@/assets/images/starLogo.png';
import styles from './Footer.module.scss';
import twitter from '@/assets/images/twitter.svg';
import insgram from '@/assets/images/insgram.svg';
import tg from '@/assets/images/telegram-original.svg';
import social from '@/assets/images/social.svg';
import discord from '@/assets/images/discord.svg';

interface FooterProps {
  className?: string;
}

export function Footer(props: FooterProps) {
  const { className } = props;

  return (
    <div className={cn(styles.Footer, className)}>
      <div className={styles.box}>
        <div className={styles.left}>
          <img src={themeLogo} alt="" className={styles.themeLogo} />
          <div className={styles.text}>Powered by Aster Foundation 2024</div>
        </div>
        <div className={styles.right}>
          <a href='https://twitter.com/intent/follow?original_referer=https%3A%2F%2Fpublish.twitter.com%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Efollow%7Ctwgr%5Eelon&region=follow_link&screen_name=starprotocol_hq' target={'_blank'} rel="noreferrer">

            <img src={twitter} alt="" />
          </a>
          {/* <a href="https://www.instagram.com/starprotocol/" target={'_blank'} rel="noreferrer">
            <img src={insgram} alt="" />
          </a> */}
          <a href="https://t.me/starprotocolhq" target={'_blank'} rel="noreferrer">
            <img src={tg} alt="" />
          </a>
          <a href="https://discord.gg/starco" target={'_blank'} rel="noreferrer">
            <img src={discord} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}
