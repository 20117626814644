export function resizeListener() {
  // 定义设计图的尺寸 3840
  let designSize = 1280;
  // 获取 html 元素
  let html = document.documentElement;
  // 定义窗口的宽度
  let clientW = html.clientWidth;
  if (clientW > 748) {
    // html 的fontsize 大小
    let htmlRem = (clientW * 12) / designSize;
    html.style.fontSize = htmlRem + 'px';
  } else {
    // html 的fontsize 大小
    let htmlRem = (clientW * 12) / 390;
    html.style.fontSize = htmlRem + 'px';
  }

  // let htmlRem = (clientW * 12) / designSize;
  // html.style.fontSize = htmlRem + 'px';

  // console.log(clientW);
}
