import cn from 'classnames';
import React from 'react';

import styles from './DigitalRealms.module.scss';
import light from '@/assets/images/light.png';
import useWindowSize from '../../hooks/useWindowSize';

interface DigitalRealmsProps {
  className?: string;
}

export function DigitalRealms(props: DigitalRealmsProps) {
  const { className } = props;
  const size = useWindowSize();

  return (
    <div className={cn(styles.DigitalRealms, className)}>
      <div className={styles.box}>
        <div className={styles.title}>
          {`Navigate the Future,
Amplify the Vision`}
        </div>
        <div className={styles.desc}>
          {size[0] > 768
            ? 'Step into the future with Star, where every object powers new possibilities for people and the world.'
            : `Step into the future with Star,
 where every object powers new possibilities
  for people and the world.`}
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        {/* <img src={light} alt="" className={styles.light} /> */}
      </div>
    </div>
  );
}
