import cn from 'classnames';
import React from 'react';

import styles from './Platform.module.scss';
import layerZero from '@/assets/images/platform/layerZero.svg';
import polygon from '@/assets/images/platform/polygon.svg';
import emade from '@/assets/images/platform/emade.png';
import consensys from '@/assets/images/platform/cconsensys.svg';
import kroma from '@/assets/images/platform/kroma.svg';
import onekey from '@/assets/images/platform/onekey.png';
import solanr from '@/assets/images/platform/solana.png';
import useWindowSize from '../../hooks/useWindowSize';

interface PlatformProps {
  className?: string;
}

export function Platform(props: PlatformProps) {
  const { className } = props;
  const size = useWindowSize();

  return (
    <div className={cn(styles.Platform, className)}>
      {size[0] > 768 ? (
        <>
          <img src={consensys} alt="" className={styles.consensysImg} />
          <img src={onekey} alt="" className={styles.onekeyImg} />

          <img src={polygon} alt="" className={styles.polygonImg} />
          <img src={layerZero} alt="" className={styles.layerZeroImg} />
          <img src={kroma} alt="" className={styles.kromaImg} />
          <img src={emade} alt="" className={styles.emadeImg} />
          <img src={solanr} alt="" className={styles.solanrImg} />
        </>
      ) : (
        <>
          <img src={polygon} alt="" className={styles.polygonImg} />
          <img src={onekey} alt="" className={styles.onekeyImg} />
          <img src={kroma} alt="" className={styles.kromaImg} />
          <img src={consensys} alt="" className={styles.consensysImg} />
          <img src={solanr} alt="" className={styles.solanrImg} />

          <img src={emade} alt="" className={styles.emadeImg} />
          <img src={layerZero} alt="" className={styles.layerZeroImg} />
        </>
      )}
    </div>
  );
}
