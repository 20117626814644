export const partaner = [
  {
    projectname: 'Suiet',
    category: 'Wallet',
    logourl: 'https://s2.loli.net/2023/06/15/kVCIABiGOgX65US.png',
    projecturl: ' https://suiet.app/',
  },
  {
    projectname: 'Morphis',
    category: 'Wallet',
    logourl: 'https://s2.loli.net/2023/06/15/PM2eTb5vDX3xHKI.png',
    projecturl: 'https://morphiswallet.com/',
  },
  {
    projectname: 'Furion',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/15/nOyESYuRG8B2JaD.png',
    projecturl: ' https://furion.io/',
  },
  {
    projectname: 'Atticc',
    category: 'Social',
    logourl: 'https://s2.loli.net/2023/06/15/qsyQEvLiV7KzTj8.png',
    projecturl: ' https://atticc.xyz/',
  },
  {
    projectname: 'Magic Square',
    category: 'Tool',
    logourl: 'https://s2.loli.net/2023/06/21/WZ3QG486dIlKH7V.png',
    projecturl: ' https://magic.store/ ',
  },
  {
    projectname: 'Ethos Wallet',
    category: 'Wallet',
    logourl: 'https://s2.loli.net/2023/06/15/9ohtuncCwIyK2SY.jpg',
    projecturl: ' https://ethoswallet.xyz/ ',
  },
  {
    projectname: 'Tocen',
    category: 'Tool',
    logourl: 'https://s2.loli.net/2023/06/16/ltVb2rZnvHIa6cD.jpg',
    projecturl: 'https://tocen.co/',
  },
  {
    projectname: 'Web3Port',
    category: 'Tool',
    logourl: 'https://s2.loli.net/2023/06/16/wCVa6Tv5K9FHIM4.png',
    projecturl: 'https://www.web3port.us/',
  },
  {
    projectname: 'Cetus',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/21/JNxIMniOBVmLTel.png',
    projecturl: 'https://www.cetus.zone/',
  },
  {
    projectname: 'AptosLaunch',
    category: 'Tool',
    logourl: 'https://s2.loli.net/2023/06/21/pyXH19CMsYrqeTR.png',
    projecturl: 'https://aptoslaunch.io/',
  },
  {
    projectname: 'Fenture Finance',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/21/M9pZ4SfcbCjaJuq.png',
    projecturl: ' https://fenture.io/',
  },
  {
    projectname: 'XPLUS',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/21/wC7EfieSyk6QWmY.png',
    projecturl: ' https://www.xplus.com/',
  },
  {
    projectname: 'Web3MQ',
    category: 'Tool',
    logourl: 'https://s2.loli.net/2023/06/21/S1RAJxE2mBMGFfa.png',
    projecturl: 'https://www.swapchat.me/',
  },
  {
    projectname: 'Arcana network',
    category: 'Tool',
    logourl: 'https://s2.loli.net/2023/06/21/H2eCfJ6WMSKRN4k.png',
    projecturl: ' https://www.arcana.network/',
  },
  {
    projectname: 'Oland',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/06/19/YcGm8MpLhbvTzS2.jpg',
    projecturl: 'https://www.oland.info/home',
  },
  {
    projectname: 'Assure Wallet',
    category: 'Wallet',
    logourl: 'https://s2.loli.net/2023/06/19/kaZcKbO2gysDh7H.jpg',
    projecturl: 'https://www.assure.pro/#/',
  },
  {
    projectname: 'Starship finance',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/15/RWgBSjolEUXGzbP.jpg',
    projecturl: ' https://starshipfinance.io/',
  },
  {
    projectname: 'Algoblocks',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/21/NGK6gloFH3aqQnr.png',
    projecturl: 'https://www.algoblocks.io/',
  },
  {
    projectname: 'QuestN',
    category: 'Tool',
    logourl: 'https://s2.loli.net/2023/06/16/jTutWchz5d3sZqi.png',
    projecturl: ' https://questn.com/',
  },
  {
    projectname: 'Eternal Finance',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/21/LZIApYbtHixCrUM.png',
    projecturl: ' https://eternalfinance.io/',
  },
  {
    projectname: 'Jujube Finance',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/16/gMDNcY7dbiZ2h3P.png',
    projecturl: ' https://www.jujube.finance/',
  },
  {
    projectname: 'Nightly Wallet',
    category: 'Wallet',
    logourl: 'https://s2.loli.net/2023/06/16/gvc6xfeHU3W8nhy.png',
    projecturl: ' https://wallet.nightly.app/',
  },
  {
    projectname: 'Psychohoshi',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/06/19/ujOMK1J7QBxUwea.jpg',
    projecturl: 'https://psychohoshi.com/#slide1',
  },
  {
    projectname: 'veDao',
    category: 'DAO',
    logourl: 'https://s2.loli.net/2023/06/16/mUhoYqD3Esk2vfQ.png',
    projecturl: 'https://starshipfinance.io/',
  },
  {
    projectname: '0xgen',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/21/TshrVF5BWCoLf9c.png',
    projecturl: 'https://starshipfinance.ihttps://0xgen.io/',
  },
  {
    projectname: 'Blockombat',
    category: 'Game',
    logourl: 'https://s2.loli.net/2023/06/16/YlFHIG9dQokAWsr.png',
    projecturl: 'https://blockombat.com/',
  },
  {
    projectname: 'Tsunami Finance',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/16/pbIgczjiKqEPu9e.png',
    projecturl: 'https://tsunami.finance/',
  },
  {
    projectname: 'MetaVoizz',
    category: 'Game',
    logourl: 'https://s2.loli.net/2023/06/21/VyiX2tIrmkY45OR.png',
    projecturl: ' https://www.metavoizz.com/',
  },
  {
    projectname: 'Deri Protocol',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/16/gZRpd8IV7MoGCNE.png',
    projecturl: 'https://deri.io/#/index',
  },
  {
    projectname: 'Hibiki Run',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/06/21/1dEByhQqaYoFHUL.png',
    projecturl: 'https://hibikirun.com/',
  },
  {
    projectname: 'Metamirror',
    category: 'Infrastructure',
    logourl: 'https://s2.loli.net/2023/06/21/Cze29TKLGsAtdng.png',
    projecturl: 'https://metamirror.space/',
  },
  {
    projectname: 'Max Protocol',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/06/21/b47VGEUCd1vmzTj.png',
    projecturl: 'https://maxity.io/',
  },
  {
    projectname: 'Metaspacecy',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/06/16/DvxaNK2fCRpSPtA.png',
    projecturl: 'https://metaspacecy.com/',
  },
  {
    projectname: 'ERA',
    category: 'Game',
    logourl: 'https://s2.loli.net/2023/06/16/CyUDcFz2Qbxio8l.jpg',
    projecturl: 'https://era.game/',
  },
  {
    projectname: 'Times finace',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/21/MaARknOmXoLy5PE.png',
    projecturl: 'https://www.timesfinance.io/',
  },
  {
    projectname: 'Vns Network',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/16/hMXa67EOIDdGjbP.png',
    projecturl: 'http://vnsnetwork.finance/',
  },
  {
    projectname: 'Dmail Network',
    category: 'Infrastructure',
    logourl: 'https://s2.loli.net/2023/06/16/5K1xoF2ZPHJAtsL.jpg',
    projecturl: 'https://dmail.ai/',
  },
  {
    projectname: 'Clique ',
    category: 'Social',
    logourl: 'https://s2.loli.net/2023/06/21/OGlwrC1JAkhtyQU.png',
    projecturl: 'https://clique.social/',
  },
  {
    projectname: 'Debox',
    category: 'Social',
    logourl: 'https://s2.loli.net/2023/06/21/PmT2esI8SChoxkY.png',
    projecturl: 'https://debox.pro/',
  },
  {
    projectname: 'PublicAI',
    category: 'DAO',
    logourl: 'https://s2.loli.net/2023/06/16/Q4au6A5ZLKDpmTI.png',
    projecturl: 'https://publicai.io/',
  },
  {
    projectname: 'Betamars',
    category: 'Game',
    logourl: 'https://s2.loli.net/2023/06/16/8aW4S25NitbCmLP.png',
    projecturl: 'https://betamars.io/web/home/',
  },
  {
    projectname: 'DOEX',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/19/9TlSQ4rjDPw2Kb8.png',
    projecturl: 'https://www.doex.com/v/',
  },
  {
    projectname: 'Coinstages',
    category: 'Tool',
    logourl: 'https://s2.loli.net/2023/06/16/tiDVvMae8sonz7h.png',
    projecturl: 'https://coinstages.com/',
  },
  {
    projectname: 'Eggworld',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/06/16/xWglAPnFrm8o745.jpg',
    projecturl: 'https://eggworld.app/',
  },
  {
    projectname: 'Charity DAO',
    category: 'DAO',
    logourl: 'https://s2.loli.net/2023/06/19/Zp4bLMhJdRU821G.jpg',
    projecturl: 'https://charitydao.co/',
  },
  {
    projectname: 'zkAnimals',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/06/16/e9wvHWThPQugdfK.png',
    projecturl: 'https://zkanimal.xyz/',
  },
  {
    projectname: 'Vaissel',
    category: 'Game',
    logourl: 'https://s2.loli.net/2023/06/16/UvKhNlTakHYLZQb.png',
    projecturl: 'https://vaissel.xyz/',
  },
  {
    projectname: 'Argent',
    category: 'Wallet',
    logourl: 'https://s2.loli.net/2023/06/16/n7QxMPXowF6U5GN.png',
    projecturl: 'https://www.argent.xyz/',
  },
  {
    projectname: 'SyncSwap',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/21/1HMcy2fZIR5VjFJ.png',
    projecturl: 'https://syncswap.xyz/',
  },
  {
    projectname: 'Fox Wallet',
    category: 'Wallet',
    logourl: 'https://s2.loli.net/2023/06/16/urjQYReMkpEv1sO.png',
    projecturl: 'https://foxwallet.com/',
  },
  {
    projectname: 'RSS3',
    category: 'Infrastructure',
    logourl: 'https://s2.loli.net/2023/06/16/OhyuD7Kpb8rfYQ1.jpg',
    projecturl: 'https://rss3.io/',
  },
  {
    projectname: 'Element Market',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/16/N6pJgT5CSM1hrFO.jpg',
    projecturl: 'https://element.market/',
  },
  {
    projectname: 'Hamster',
    category: 'Tool',
    logourl: 'https://s2.loli.net/2023/06/16/LDx4TgCaPcNY93B.png',
    projecturl: 'https://hamsternet.io/',
  },
  {
    projectname: 'Metachat',
    category: 'Social',
    logourl: 'https://s2.loli.net/2023/06/16/CksZ6trYd91lhMN.png',
    projecturl: 'https://dappad.app/',
  },
  {
    projectname: 'Rhino',
    category: 'Bridge',
    logourl: 'https://s2.loli.net/2023/06/16/dXPOoGieJARjpMC.png',
    projecturl: 'https://app.rhino.fi/bridge',
  },
  {
    projectname: 'Spacefi',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/16/g1pon9D26Cb7VjH.png',
    projecturl: 'https://www.spacefi.io/',
  },
  {
    projectname: 'zkBoost',
    category: 'Tool',
    logourl: 'https://s2.loli.net/2023/06/16/NOtmqk1Fyis9Spg.png',
    projecturl: 'https://zkboost.finance/',
  },
  {
    projectname: 'Mute.io',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/16/cnIlHqpWuS8w5za.png',
    projecturl: 'https://mute.io/',
  },
  {
    projectname: 'Bitget',
    category: 'Wallet',
    logourl: 'https://s2.loli.net/2023/10/18/M6KbFZkctPU94GC.jpg',
    projecturl: 'https://bitkeep.com/',
  },
  {
    projectname: 'OKX Web3',
    category: 'Wallet',
    logourl: 'https://s2.loli.net/2023/06/16/VBdQRspNZDwJFGY.png',
    projecturl: 'https://www.okx.com/cn/web3',
  },
  {
    projectname: 'Ave.ai',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/16/4QzJgForUlkLsfh.png',
    projecturl: 'https://ave.ai/',
  },
  {
    projectname: 'ZkRock',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/06/16/YSR7qEs6CL4uTnZ.jpg',
    projecturl: 'https://www.zkrock.xyz/#/ ',
  },
  {
    projectname: 'MesProtocol',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/16/rHXJK7TFnNja1tE.png',
    projecturl: 'https://www.mesprotocol.com/',
  },
  {
    projectname: 'Goal3',
    category: 'Game',
    logourl: 'https://s2.loli.net/2023/06/16/ZWaFKRy8uT6Hbio.jpg',
    projecturl: 'https://beta.goal3.xyz/',
  },
  {
    projectname: 'Orbiter',
    category: 'Bridge',
    logourl: 'https://s2.loli.net/2023/06/16/9rdcT8vlJKEAHUa.jpg',
    projecturl: 'https://www.orbiter.finance/?source=Ethereum&dest=Arbitrum',
  },
  {
    projectname: 'Meson',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/21/gGUjiM8d1sSAB72.png',
    projecturl: 'https://meson.fi/zh',
  },
  {
    projectname: 'Kekkai',
    category: 'Tool',
    logourl: 'https://s2.loli.net/2023/06/16/kx6stjBSHGNyLEa.png',
    projecturl: 'https://kekkai.io/',
  },
  {
    projectname: 'EraLend',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/21/flsGNdubKmE7IF9.png',
    projecturl: 'https://www.eralend.com/',
  },
  {
    projectname: 'Getaverse',
    category: 'Infrastructure',
    logourl: 'https://s2.loli.net/2023/06/16/xCkdYGX8mKFy5U1.jpg',
    projecturl: 'https://getaverses.com/',
  },
  {
    projectname: ' zkVeggies ',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/06/16/jGKDF7eUR4EBVLg.jpg',
    projecturl: ' https://zkveggies.com/',
  },
  {
    projectname: 'Spores',
    category: 'Tool',
    logourl: 'https://s2.loli.net/2023/06/21/HBfveLVNiGgOhYl.png',
    projecturl: 'https://launchpad.spores.app/',
  },
  {
    projectname: 'Izumi',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/16/MrQPJ5oVc6j27i4.png',
    projecturl: 'https://izumi.finance/home',
  },
  {
    projectname: 'X Winner',
    category: 'Game',
    logourl: 'https://s2.loli.net/2023/06/21/gW58NTf9JMw41bl.png',
    projecturl: ' https://xwinner.app/',
  },
  {
    projectname: 'Townstory',
    category: 'Game',
    logourl: 'https://s2.loli.net/2023/06/16/uXLNbynaD5AsUGd.jpg',
    projecturl: ' https://townstory.io/ ',
  },
  {
    projectname: 'Ultiverse',
    category: 'Game',
    logourl: 'https://s2.loli.net/2023/06/21/vQUZNdRCWwlBpac.png',
    projecturl: ' https://www.ultiverse.io/ ',
  },
  {
    projectname: 'Openblock Wallet',
    category: 'Wallet',
    logourl: 'https://s2.loli.net/2023/06/16/RENqUtmKi1GAWrl.png',
    projecturl: 'https://openblock.com/#/ ',
  },
  {
    projectname: 'ChainGPT',
    category: 'Tool',
    logourl: 'https://s2.loli.net/2023/06/16/USjfdWQCpERGts5.png',
    projecturl: 'https://www.chaingpt.org/ ',
  },
  {
    projectname: 'ApeSwap',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/21/IprJ1D5YKmyHQTn.png',
    projecturl: 'https://apeswap.finance/',
  },
  {
    projectname: 'Blockin.ai',
    category: 'Tool',
    logourl: 'https://s2.loli.net/2023/06/21/8wAUMRhzyxtSiaT.png',
    projecturl: ' https://www.blockin.ai/',
  },
  {
    projectname: 'MetaOasis',
    category: 'Game',
    logourl: 'https://s2.loli.net/2023/06/16/Nlwbq3825EZHOtF.png',
    projecturl: 'https://www.metaoasis.cc/',
  },
  {
    projectname: 'Forward Protocol',
    category: 'Tool',
    logourl: 'https://s2.loli.net/2023/06/16/5NoFU6PhnGJ2cZM.png',
    projecturl: 'https://forwardprotocol.io/',
  },
  {
    projectname: 'Labelled',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/16/EOvspr5IgALKZPT.png',
    projecturl: 'https://theacemeta.io/',
  },
  {
    projectname: 'Dackie Labs',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/06/21/goR9LPOVTwysXQk.png',
    projecturl: 'https://www.dackieswap.xyz/',
  },
  {
    projectname: 'Innovaz',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/06/21/x5OCavmVzdsoeiJ.png',
    projecturl: 'https://innovaz.io/',
  },
  {
    projectname: 'HyperPay',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/06/16/3mFunXwlRp5AkIg.png',
    projecturl: 'https://www.hyperpay.tech/',
  },
  {
    projectname: 'TokenPocket',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/06/21/dQL3PwbIxWvOnsH.png',
    projecturl: 'https://www.tokenpocket.pro/',
  },
  {
    projectname: 'Dawn Refuge',
    category: 'Game',
    logourl: 'https://s2.loli.net/2023/06/19/a2NjAPolfVxRCm1.png',
    projecturl: 'https://zkdawnrefuge.xyz/',
  },
  {
    projectname: 'Squid',
    category: 'Bridge',
    logourl: 'https://s2.loli.net/2023/07/20/YJh4CXT1dkaQeW2.png',
    projecturl: 'https://www.squidrouter.com/',
  },
  {
    projectname: 'Derp Dex',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/07/20/BtFfzSNHO4LXU9w.jpg',
    projecturl: 'https://derpdex.com/',
  },
  {
    projectname: 'BeagleSwap',
    category: 'game',
    logourl: 'https://s2.loli.net/2023/07/20/szR1WrkhBj2byfo.png',
    projecturl: 'https://beagleswap.xyz/',
  },
  {
    projectname: 'XY Finance',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/07/20/aEbNhBozrX5wW17.png',
    projecturl: 'https://app.xy.finance/',
  },
  {
    projectname: 'Cheems',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/07/20/liMTgHVrwWdFYXm.jpg',
    projecturl: 'https://cheems.pet/',
  },
  {
    projectname: 'TABI',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/07/20/dhjgASMH9mIefxt.png',
    projecturl: 'https://tabi.lol/',
  },
  {
    projectname: 'zkHoldstation',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/07/20/tmfhUBCdlG2kg7K.png',
    projecturl: 'https://holdstation.exchange/',
  },
  {
    projectname: 'Omnisea',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/07/20/TBY6erSUO4xKlEN.png',
    projecturl: 'https://omnisea.org/',
  },
  {
    projectname: 'Pheasant Network',
    category: 'Bridge',
    logourl: 'https://s2.loli.net/2023/07/20/Sv7XwjiTeFJ2aHM.png',
    projecturl: 'https://pheasant.network/',
  },
  {
    projectname: 'Peko Protocol',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/07/20/WGNo5RkHy8gEdA9.png',
    projecturl: 'https://www.pekoprotocol.xyz/',
  },
  {
    projectname: 'Zkfox',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/07/20/wVAMdbfK8X32DcZ.png',
    projecturl: 'https://zkfox.io/#/',
  },
  {
    projectname: 'SyncPad',
    category: 'Launchpad',
    logourl: 'https://s2.loli.net/2023/07/20/6YqvDLIlzS8ca2K.jpg',
    projecturl: 'https://syncpad.co/',
  },
  {
    projectname: 'veSync',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/07/20/1RKGY6gJDiektha.png',
    projecturl: 'https://vesync.finance/',
  },
  {
    projectname: ' Doge Labs',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/07/20/5QC9ALx3bOcBy8u.jpg',
    projecturl: ' https://drc-20.org/ ',
  },
  {
    projectname: 'NFTfeed',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/07/20/O5zuoB2d8aYGMlX.png',
    projecturl: ' https://nftfeed.guru/',
  },
  {
    projectname: 'zkMarkets',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/07/20/gU5LZidnlISqAFT.png',
    projecturl: 'https://www.zkmarkets.com/',
  },
  {
    projectname: 'zkDX',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/07/20/snxr5edmploETbU.png',
    projecturl: 'https://zkdx.io/',
  },
  {
    projectname: 'Rollup Finance',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/07/20/KF3hfrQzgPVlGbA.png',
    projecturl: 'https://www.rollup.finance/',
  },
  {
    projectname: 'KaratDAO',
    category: 'Tool',
    logourl: 'https://s2.loli.net/2023/07/21/niFq7EsaOujxBh2.png',
    projecturl: 'https://karatdao.com/',
  },
  {
    projectname: 'zkswap',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/07/20/Fc1bDR3TUYmGhji.png',
    projecturl: ' https://zk-swap.xyz/ ',
  },
  {
    projectname: 'ReadON',
    category: 'Social',
    logourl: 'https://s2.loli.net/2023/07/20/pIvqkhGLaFlT4iN.jpg',
    projecturl: 'https://readon.me/',
  },
  {
    projectname: 'CyrusSwap',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/07/20/UMBd2HkEerDgbuV.jpg',
    projecturl: 'https://cyrus.exchange/',
  },
  {
    projectname: 'Metaverse Space',
    category: 'Launchpad',
    logourl: 'https://s2.loli.net/2023/07/20/vBh98kwAo3yYU5q.jpg',
    projecturl: 'http://metaversespace.io/',
  },
  {
    projectname: 'Sender Wallet',
    category: 'Wallet',
    logourl: 'https://s2.loli.net/2023/07/20/J8yQbCWmhcAqodI.png',
    projecturl: 'https://Sender.org',
  },
  {
    projectname: 'Lucky Finance',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/07/20/78Gj136fmJXRqNh.png',
    projecturl: 'https://luckyfinance.io/#/',
  },
  {
    projectname: 'Claimabl3',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/07/21/yx6B4i1HeoQNjtf.jpg',
    projecturl: 'https://www.claimabl3.xyz/',
  },
  {
    projectname: 'Zonic',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/07/20/I36ocRNnZr9BD7q.jpg',
    projecturl: 'https://zonic.app/',
  },
  {
    projectname: 'zkGames',
    category: 'Game',
    logourl: 'https://s2.loli.net/2023/07/20/fLjEmSP425CXBor.png',
    projecturl: 'https://zkgames.com/',
  },
  {
    projectname: 'Tifo.trade',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/07/20/KpugjcP21o5AOIa.jpg',
    projecturl: 'https://tifo.trade/',
  },
  {
    projectname: 'EchoDEX',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/07/20/n9IlZ3KSuvCq8wb.png',
    projecturl: 'https://www.echodex.io/swap',
  },
  {
    projectname: 'CloudBase',
    category: 'Defi',
    logourl: 'https://s2.loli.net/2023/07/21/ezEQSBm2agkD4J5.jpg',
    projecturl: 'https://cloudbase.finance/?chainId=84531',
  },
  {
    projectname: 'Particle',
    category: 'Infrastructure',
    logourl: 'https://s2.loli.net/2023/07/21/Wa24vURY7e3Fp9k.png',
    projecturl: 'https://particle.network/',
  },
  {
    projectname: 'Createra',
    category: 'Game',
    logourl: 'https://s2.loli.net/2023/07/21/1Iw3nj2KJu9WRSE.png',
    projecturl: 'https://createra.fun/',
  },
  {
    projectname: 'CloudBase',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/09/11/Be9Mq6tPxiSDIGf.jpg',
    projecturl: 'https://cloudbase.finance/?chainId=84531',
  },
  {
    projectname: 'Shareverse',
    category: 'Mataverse',
    logourl: 'https://s2.loli.net/2023/09/11/Bd38sUK9EvO2hkw.png',
    projecturl: 'https://www.test.shareverse.io/',
  },
  {
    projectname: 'RIDO',
    category: 'Tools',
    logourl: 'https://s2.loli.net/2023/09/11/tclXPb2Lq5y3O9Y.png',
    projecturl: 'https://www.rido.io/',
  },
  {
    projectname: 'LayerZero Labs',
    category: 'Infrastructure',
    logourl: 'https://s2.loli.net/2023/09/11/iNbk8gmDjUlJ7as.jpg',
    projecturl: 'https://layerzero.network/',
  },
  {
    projectname: 'Linea',
    category: 'Infrastructure',
    logourl: 'https://s2.loli.net/2023/09/11/x5ozyDSXtHnB1jc.jpg',
    projecturl: 'https://linea.build/',
  },
  {
    projectname: 'Scroll',
    category: 'Infrastructure',
    logourl: 'https://s2.loli.net/2023/09/11/jDLVCUqSYfK7AP2.jpg',
    projecturl: 'https://scroll.io/',
  },
  {
    projectname: 'zkSync',
    category: 'Infrastructure',
    logourl: 'https://s2.loli.net/2023/09/11/SLlWzu45RCiY6Xn.jpg',
    projecturl: 'https://zksync.io/',
  },
  {
    projectname: 'Optimism',
    category: 'Infrastructure',
    logourl: 'https://s2.loli.net/2023/09/11/NY3vqBHyV6K29w5.jpg',
    projecturl: 'https://www.optimism.io/',
  },
  {
    projectname: 'LineaBank',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/09/11/H6Q29pgATyejvqi.jpg',
    projecturl: 'https://lineabank.finance/',
  },
  {
    projectname: 'CyberConnect',
    category: 'Social',
    logourl: 'https://s2.loli.net/2023/09/11/53mO7LWQUT4cMrV.png',
    projecturl: 'https://cyberconnect.me/',
  },
  {
    projectname: 'Horizon Dex',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/09/12/xArzPoGS2aXNtd3.png',
    projecturl: 'https://app.horizondex.io/swap',
  },
  {
    projectname: 'Base',
    category: 'Infrastructure',
    logourl: 'https://s2.loli.net/2023/09/11/9xH3Jqgp7VKMB1o.jpg',
    projecturl: 'https://base.org/',
  },
  {
    projectname: 'Token Pocket',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/09/11/IGrp5AwdaknxeQ4.jpg',
    projecturl: 'https://www.tokenpocket.pro/',
  },
  {
    projectname: 'Pira Finance',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/09/11/YQTGvr5aCHM1EXj.jpg',
    projecturl: 'https://www.pira.finance/',
  },
  {
    projectname: 'Opside',
    category: 'Infrastructure',
    logourl: 'https://s2.loli.net/2023/09/11/lufmW4zY3kbU5PL.jpg',
    projecturl: 'https://opsi.de/',
  },
  {
    projectname: 'Intswap',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/09/11/TEweqpRUZYjz8Bm.png',
    projecturl: 'https://intswap.io/',
  },
  {
    projectname: 'Deverse',
    category: 'Mataverse',
    logourl: 'https://s2.loli.net/2023/09/11/RzdCjqFA7WMKg9P.jpg',
    projecturl: 'https://deverse.social/',
  },
  {
    projectname: 'Dyson Finance',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/09/11/hzSiCfHy6LlWQdb.jpg',
    projecturl: 'https://dyson.finance/',
  },
  {
    projectname: 'Cheelee',
    category: 'Games',
    logourl: 'https://s2.loli.net/2023/09/11/JqoleIVkzL4pb9F.jpg',
    projecturl: 'https://cheelee.io/',
  },
  {
    projectname: 'Optimism Daily',
    category: 'Media',
    logourl: 'https://s2.loli.net/2023/09/12/z7gfvJ4ycpislhP.jpg',
    projecturl: 'https://coinwire.com/',
  },
  {
    projectname: 'zkGarden',
    category: 'Games',
    logourl: 'https://s2.loli.net/2023/09/11/47q8efk5YrVgy9O.jpg',
    projecturl: 'https://zkgarden.xyz/',
  },
  {
    projectname: 'Pandaverse',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/09/12/tJPjhmBi16b4YzV.png',
    projecturl: 'https://panda-verse.xyz',
  },
  {
    projectname: 'NFTs2Me',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/09/11/YrtIS41cBQC7zNl.png',
    projecturl: 'https://nfts2me.com/',
  },
  {
    projectname: 'LOOTaDOG',
    category: 'Games',
    logourl: 'https://s2.loli.net/2023/09/11/hNRpYT5GSmztc2s.png',
    projecturl: 'https://lootadog.com/ ',
  },
  {
    projectname: 'Cwallet',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/09/11/CYzml94d87ckg31.jpg',
    projecturl: 'https://cwallet.com/',
  },
  {
    projectname: 'Paraspace',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/09/11/apxKRltVu5JQofv.png',
    projecturl: 'https://linktr.ee/paraspace',
  },
  {
    projectname: 'lineaswap',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/09/11/ezoiuMaXN42mGh7.png',
    projecturl: 'https://lineaswap.build/',
  },
  {
    projectname: 'BG Trade',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/09/11/FfVO7Mm9syD8rWu.jpg',
    projecturl: 'https://www.bgtrade.io/',
  },
  {
    projectname: 'HeartX',
    category: 'Tools',
    logourl: 'https://s2.loli.net/2023/09/11/7t6WwHDofhNJPz5.jpg',
    projecturl: 'https://www.heartx.art/explore',
  },
  {
    projectname: 'Symbiosis',
    category: 'Infrastructure',
    logourl: 'https://s2.loli.net/2023/09/13/YdKARxVwelFvsHP.jpg',
    projecturl: 'https://symbiosis.finance/?utm_source=linktree&utm_medium=social',
  },
  {
    projectname: 'velocore',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/09/11/8CNxUlk5by4m7nh.jpg',
    projecturl: 'https://linea.velocore.xyz/',
  },
  {
    projectname: 'One Key',
    category: 'Wallets',
    logourl: 'https://s2.loli.net/2023/09/12/OyIWvl3dewrQSH2.jpg',
    projecturl: 'https://onekey.so/',
  },
  {
    projectname: 'Lyve Finance',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/09/12/1bJm9C45jODdGoE.jpg',
    projecturl: 'https://www.lyvefi.xyz/',
  },
  {
    projectname: 'Linea Fans',
    category: 'Media',
    logourl: 'https://s2.loli.net/2023/09/13/wfbHAyYqk47Lx2v.jpg',
    projecturl: 'https://linea.build/',
  },
  {
    projectname: 'Plant Crossing',
    category: 'Games',
    logourl: 'https://s2.loli.net/2023/09/11/9HxkXIWptyjEKFm.png',
    projecturl: 'https://plant-crossing.com/',
  },
  {
    projectname: 'Arena of Faith',
    category: 'Games',
    logourl: 'https://s2.loli.net/2023/09/11/wES4tXdFhUxo3RJ.jpg',
    projecturl: 'https://aof.games/',
  },
  {
    projectname: 'NebulaDex',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/09/13/ps8gntExBy5KP1z.jpg',
    projecturl: 'https://nebuladex.io/mint',
  },
  {
    projectname: 'AlienSwap',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/09/12/UsiybTSew5EKurJ.jpg',
    projecturl: 'https://alienswap.xyz/',
  },
  {
    projectname: 'Optimism Hub',
    category: 'Tools',
    logourl: 'https://s2.loli.net/2023/09/12/xArzPoGS2aXNtd3.png',
    projecturl: 'https://t.me/OptimismHub',
  },
  {
    projectname: 'BaseStarter',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/09/12/UrfkuFoWXg4EB72.jpg',
    projecturl: 'https://basestarter.vip/',
  },
  {
    projectname: 'NFTFeed',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/09/13/MfHd4xwW8ErXlsn.jpg',
    projecturl: 'https://nftfeed.guru/',
  },
  {
    projectname: 'Darkpawgame',
    category: 'Social',
    logourl: 'https://s2.loli.net/2023/09/13/jtGQ1rTOly4mXKd.jpg',
    projecturl: 'https://www.darkpawgame.com/',
  },
  {
    projectname: 'Linea Zero',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/09/13/snaqfL5XBbKig9D.jpg',
    projecturl: 'https://lineazero.nfts2.me/',
  },
  {
    projectname: 'Base Recap',
    category: 'Media',
    logourl: 'https://s2.loli.net/2023/09/13/qhtjFa9ixnuDOsI.jpg',
    projecturl: 'https://twitter.com/BaseRecap',
  },
  {
    projectname: 'Base Fans',
    category: 'Media',
    logourl: 'https://s2.loli.net/2023/09/13/XhrAVNCz1oujy5i.jpg',
    projecturl: 'https://twitter.com/BaseEcoFans',
  },
  {
    projectname: 'Base Station',
    category: 'Media',
    logourl: 'https://s2.loli.net/2023/09/12/mrC6OT71D3egzsx.jpg',
    projecturl: 'https://chainstation.io/sui',
  },
  {
    projectname: 'DaoStarter',
    category: 'Launchpad',
    logourl: 'https://s2.loli.net/2023/09/13/wmVIxB4OCrk5Kcj.jpg',
    projecturl: 'https://www.daostarter.pro/#/index',
  },
  {
    projectname: 'Scroll Recap',
    category: 'Media',
    logourl: 'https://s2.loli.net/2023/09/13/hZdbUnQYSAL2Dzm.jpg',
    projecturl: 'https://twitter.com/ScrollRecap',
  },
  {
    projectname: 'AITECH',
    category: 'Tools',
    logourl: 'https://s2.loli.net/2023/09/12/VQWPjmFv1nbUf94.jpg',
    projecturl: 'https://www.aitech.io/',
  },
  {
    projectname: 'Base Daily',
    category: 'Media',
    logourl: 'https://s2.loli.net/2023/09/12/bnvXQoU4tH3x71w.jpg',
    projecturl: 'https://basevoyage.com/',
  },
  {
    projectname: 'DustSwap',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/09/13/dy5hulYnbq4PGmQ.jpg',
    projecturl: 'https://www.dustswap.io/',
  },
  {
    projectname: 'Dego Finance',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/09/12/kzLGs8hHWZAxTal.jpg',
    projecturl: 'https://dego.finance/',
  },
  {
    projectname: 'Owlto Finance',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/09/12/7Xl3y9vh2TV5sSY.jpg',
    projecturl: 'https://owlto.finance/',
  },
  {
    projectname: 'Fomoin',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/09/12/aofPV6ps5R2LjcJ.jpg',
    projecturl: 'https://fomoin.finance/#/',
  },
  {
    projectname: 'TaskOn',
    category: 'Tools',
    logourl: 'https://s2.loli.net/2023/09/12/mNkdHetVpTSDu6I.jpg',
    projecturl: 'https://taskon.xyz/',
  },
  {
    projectname: 'Zealy',
    category: 'Tools',
    logourl: 'https://s2.loli.net/2023/09/15/Sn6PBkwJq42CZV9.jpg',
    projecturl: 'https://zealy.io/',
  },
  {
    projectname: 'cochilli',
    category: 'Games',
    logourl: 'https://s2.loli.net/2023/09/20/7z4CUOsD3rEANqa.jpg',
    projecturl: 'https://cochilli.io/',
  },
  {
    projectname: 'Rollup Finance',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/09/15/lROreDxA54z1g2K.png',
    projecturl: 'https://www.rollup.finance/',
  },
  {
    projectname: 'Bilinear.io ',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/09/15/EcVde3TACx6Ko7B.jpg',
    projecturl: 'https://www.bilinear.io/',
  },
  {
    projectname: 'Safary',
    category: 'Tools',
    logourl: 'https://s2.loli.net/2023/09/20/ZAVofbv6JU4pgIl.png',
    projecturl: 'https://linktr.ee/safaryclub',
  },
  {
    projectname: 'Layer2 Index',
    category: 'Media',
    logourl: 'https://s2.loli.net/2023/09/20/8yvBjVEG4Oh2Ips.jpg',
    projecturl: 'https://twitter.com/indexlayer2',
  },
  {
    projectname: 'zkSkulls',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/09/20/XNBqD85dmZf74Ce.jpg',
    projecturl: 'https://zkskulls.com/',
  },
  {
    projectname: 'HypoDrop',
    category: 'DAO',
    logourl: 'https://s2.loli.net/2023/09/28/CknYp4eBsRULAhK.png',
    projecturl: 'https://twitter.com/Hypodrop',
  },
  {
    projectname: 'DeGame',
    category: 'Tools',
    logourl: 'https://s2.loli.net/2023/09/28/c9IAvZ1xU5paeRF.png',
    projecturl: 'https://degame.com/',
  },
  {
    projectname: 'xworldgames',
    category: 'Games',
    logourl: 'https://s2.loli.net/2023/09/28/LFXHk8ThxiastGC.jpg',
    projecturl: 'https://www.xwg.games/',
  },
  {
    projectname: 'Hue',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/09/28/7jdgYcGqDf1QzKo.jpg',
    projecturl: 'https://huenft.com/',
  },
  {
    projectname: 'Lamas Finance',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/10/19/k7bBpNs3Tw2tEyh.jpg',
    projecturl: 'https://www.lamas.co/',
  },
  {
    projectname: 'Giveaway.com',
    category: 'Tools',
    logourl: 'https://s2.loli.net/2023/09/28/KvCyDrpcAdo87xL.jpg',
    projecturl: 'https://giveaway.com/',
  },
  {
    projectname: 'Scroll Guide',
    category: 'Media',
    logourl: 'https://s2.loli.net/2023/09/28/9MAXaGtIH3bwsFO.jpg',
    projecturl: 'https://twitter.com/ScrollGuide',
  },
  {
    projectname: 'Sui Community',
    category: 'Media',
    logourl: 'https://s2.loli.net/2023/10/18/ROXd7naTslBGJcL.jpg',
    projecturl: 'https://linktr.ee/suicommunity',
  },
  {
    projectname: 'Scroll Community',
    category: 'Media',
    logourl: 'https://s2.loli.net/2023/10/18/KjlIwdFm4qG7R8e.jpg',
    projecturl: 'https://twitter.com/Scroll_CM',
  },
  {
    projectname: 'IvySwap_io',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/10/18/CxgV1d6iRWAwXTf.jpg',
    projecturl: 'https://ivyswap.io/',
  },
  {
    projectname: 'CattieSwap',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/10/18/TABm6dqhocxC4Nk.jpg',
    projecturl: 'https://www.cattieswap.xyz/',
  },
  {
    projectname: 'Scroll Build',
    category: 'Media',
    logourl: 'https://s2.loli.net/2023/10/18/EScaK3sC5ldnUmb.jpg',
    projecturl: 'https://twitter.com/ScrollBuild',
  },
  {
    projectname: 'fulcrom finance',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/10/18/D7do3LibI4YueWg.jpg',
    projecturl: 'https://fulcrom.finance/en/',
  },
  {
    projectname: 'Scroll Pad',
    category: 'Launchpad',
    logourl: 'https://s2.loli.net/2023/10/18/r6KIUME12eYzxJu.jpg',
    projecturl: 'https://www.scrollpad.org/',
  },
  {
    projectname: 'LightCycle',
    category: 'Games',
    logourl: 'https://s2.loli.net/2023/11/17/NksR52oU4Ewm1qZ.jpg',
    projecturl: 'https://lightcycle.city/',
  },
  {
    projectname: '第一社区&Community',
    category: 'DAO',
    logourl: 'https://s2.loli.net/2023/11/17/5f4LqAwGMaKtYIm.jpg',
    projecturl: 'https://twitter.com/DiYi_Community',
  },
  {
    projectname: 'Scroll AllDay ',
    category: 'Media',
    logourl: 'https://s2.loli.net/2023/11/17/gpEL4WoBaKcAOfY.jpg',
    projecturl: 'https://twitter.com/Scroll_AllDay',
  },
  {
    projectname: 'Script Network',
    category: 'Tools',
    logourl: 'https://s2.loli.net/2023/11/20/EeKG6Nwn1vCR4aJ.jpg',
    projecturl: 'https://link3.to/scriptnetwork',
  },
  {
    projectname: 'Nabox',
    category: 'Tools',
    logourl: 'https://s2.loli.net/2023/11/20/LGjsQPvAq3flcBD.png',
    projecturl: 'https://id.nabox.io/profile?ID=nabox',
  },
  {
    projectname: 'LuigiSwap',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/11/20/AYKP745klIpyDcd.jpg',
    projecturl: 'https://luigiswap.finance/swap/',
  },
  {
    projectname: 'Hippo Swap',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/11/20/L7PdBl1k5tJhmcC.jpg',
    projecturl: 'https://hipposwap.finance/',
  },
  {
    projectname: 'Rise Of Scroll',
    category: 'Games',
    logourl: 'https://s2.loli.net/2023/11/20/QhslRSew9dfqu7b.jpg',
    projecturl: 'https://riseofscroll.art/',
  },
  {
    projectname: 'Loozr',
    category: 'Launchpad',
    logourl: 'https://s2.loli.net/2023/11/20/bAKQ3Zs9BPv26Cj.jpg',
    projecturl: 'https://www.loozr.io/',
  },
  {
    projectname: 'Sypool Protocol',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/11/20/A3SGnEai6FvDZVM.jpg',
    projecturl: 'https://sypool.io/',
  },
  {
    projectname: 'Vital Block',
    category: 'Launchpad',
    logourl: 'https://s2.loli.net/2023/11/20/IxvwK8je9zpaJku.jpg',
    projecturl: 'https://vitalblock.org/',
  },
  {
    projectname: 'Coffee Dex',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/11/20/eEoACgKiDkBdPNQ.jpg',
    projecturl: 'https://www.coffeefi.xyz/',
  },
  {
    projectname: 'Jump.trade',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/11/20/NJPWwSZFhIixY9d.jpg',
    projecturl: 'https://www.jump.trade/?fsz=TW',
  },
  {
    projectname: 'Gosleep',
    category: 'Tools',
    logourl: 'https://s2.loli.net/2023/11/20/sLkfjPqaRxnC7Tv.jpg',
    projecturl: 'https://gosleep.pro/',
  },
  {
    projectname: 'OpenPad',
    category: 'Fund',
    logourl: 'https://s2.loli.net/2023/11/20/aIQGCbBM8l1ewA3.jpg',
    projecturl: 'https://openpad.io/homepage',
  },
  {
    projectname: 'PLEXUS',
    category: 'Infrastructure',
    logourl: 'https://s2.loli.net/2023/11/20/yrTjFcPBnULKZQd.jpg',
    projecturl: 'https://www.plexus.app/',
  },
  {
    projectname: 'Rollie Finance',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/11/20/FkJHvTm8XyIg3pu.jpg',
    projecturl: 'https://www.rollie.finance/',
  },
  {
    projectname: 'Scroll Journal ',
    category: 'Media',
    logourl: 'https://s2.loli.net/2023/11/20/DHrXTO17jEG6N4V.jpg',
    projecturl: 'https://link3.to/scroll_journal',
  },
  {
    projectname: 'WeFi',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/11/20/7HhJVWdCmElMOxj.jpg',
    projecturl: 'https://wefi.xyz/',
  },
  {
    projectname: 'Linea Universe',
    category: 'Media',
    logourl: 'https://s2.loli.net/2023/11/20/pV5etdkFzSNs8IO.jpg',
    projecturl: 'https://twitter.com/Linea_Universe',
  },
  {
    projectname: 'Skydrome ',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/11/20/GKuiqrkEQCRjmNd.jpg',
    projecturl: 'https://skydrome.finance/',
  },
  {
    projectname: 'Scroll Space',
    category: 'Media',
    logourl: 'https://s2.loli.net/2023/11/20/p3Xz2YNLEdx5Kf6.jpg',
    projecturl: 'https://twitter.com/ScrollSpace_',
  },
  {
    projectname: 'ScrollPad',
    category: 'Launchpad',
    logourl: 'https://s2.loli.net/2023/11/20/716qUAOIVZzuQWr.jpg',
    projecturl: 'https://scrollpad.network/',
  },
  {
    projectname: 'Scroll Punk',
    category: 'NFT',
    logourl: 'https://s2.loli.net/2023/11/20/E4OMNTbFflmv52Q.jpg',
    projecturl: 'https://www.scrollpunk.info/',
  },
  {
    projectname: 'Yuliverse',
    category: 'Games',
    logourl: 'https://s2.loli.net/2023/11/20/n4LWSR82mQZzv3P.jpg',
    projecturl: 'https://www.yuliverse.com/',
  },
  {
    projectname: 'Pilotdog.tech',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/11/20/CPSVrcay8wmiQGR.jpg',
    projecturl: 'https://twitter.com/Pilotdog2049',
  },
  {
    projectname: 'NFT-Rex Web3',
    category: 'Tools',
    logourl: 'https://s2.loli.net/2023/11/20/4XYJOxlqoQDgdSV.jpg',
    projecturl: 'https://twitter.com/NFTRexWeb3',
  },
  {
    projectname: 'ScrollSpaceX ',
    category: 'Media',
    logourl: 'https://s2.loli.net/2023/11/20/SGqR1TomVgX74b6.jpg',
    projecturl: 'https://twitter.com/ScrollSpaceX',
  },
  {
    projectname: 'Starmaker',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/11/20/5DkroJ74cUyXpxH.jpg',
    projecturl: 'https://starmaker.exchange/',
  },
  {
    projectname: 'zkcodex',
    category: 'Wallets',
    logourl: 'https://s2.loli.net/2023/11/20/pIgf3Jcy6o2snHh.jpg',
    projecturl: 'http://zkcodex.com/',
  },
  {
    projectname: 'splatter',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/11/20/pyT41jDRoxF5SYU.jpg',
    projecturl: 'https://www.splatterprotocol.xyz/app/swap',
  },
  {
    projectname: 'zkPerp',
    category: 'Media',
    logourl: 'https://s2.loli.net/2023/11/20/tYUpZHh91BOyPgw.jpg',
    projecturl: 'https://www.zkperp.tech/',
  },
  {
    projectname: 'shiba scroll',
    category: 'Media',
    logourl: 'https://s2.loli.net/2023/11/20/JA5Si7MGmrsvcxo.jpg',
    projecturl: 'https://twitter.com/ScrollPadZKP',
  },
  {
    projectname: 'FLock.io',
    category: 'Infrastructure',
    logourl: 'https://s2.loli.net/2023/11/20/1ExlmytYucoTDiW.jpg',
    projecturl: 'https://flock.io/#/',
  },
  {
    projectname: 'Dechat',
    category: 'Tools',
    logourl: 'https://s2.loli.net/2023/12/07/9QsfWlYeycwunpg.jpg',
    projecturl: 'https://www.dechat.io/home',
  },
  {
    projectname: 'zkSync Station',
    category: 'Media',
    logourl: 'https://s2.loli.net/2023/12/07/HL2GMo53BRvNDAW.jpg',
    projecturl: 'https://chainstation.io/sui',
  },
  {
    projectname: 'ZeroLend',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/12/07/bFYE6nB7LCAhfHX.jpg',
    projecturl: 'https://zerolend.xyz/',
  },
  {
    projectname: 'alpharadarbot',
    category: 'Tools',
    logourl: 'https://s2.loli.net/2023/12/07/1S7YmvIJskbxTLK.jpg',
    projecturl: 'https://alpharadarbot.xyz/',
  },
  {
    projectname: 'Mar3 ',
    category: 'Social',
    logourl: 'https://s2.loli.net/2023/12/07/OQASiBUoHMu2afC.jpg',
    projecturl: 'https://mar3.ai/homepage',
  },
  {
    projectname: 'Arc Finance',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/12/07/438LY7CJyPXAcua.jpg',
    projecturl: 'https://twitter.com/Arc__Finance',
  },
  {
    projectname: 'Deepswap',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/12/07/zGr93dKk5FMHJ64.jpg',
    projecturl: 'https://twitter.com/DeepSwapp',
  },
  {
    projectname: 'Rese',
    category: 'Social',
    logourl: 'https://s2.loli.net/2023/12/07/QoKWaihtjqrHupe.jpg',
    projecturl: 'https://rese.social/',
  },
  {
    projectname: 'DMission Zone',
    category: 'Tools',
    logourl: 'https://s2.loli.net/2023/12/07/u2wQPJasboLRN4h.jpg',
    projecturl: 'https://dmission.me/',
  },
  {
    projectname: 'Micro3',
    category: 'DEFI',
    logourl: 'https://s2.loli.net/2023/12/07/MPuHD6I3Tdmi9S8.jpg',
    projecturl: 'https://micro3.io/',
  },
  {
    projectname: 'Sonorus',
    category: 'Tools',
    logourl: 'https://s2.loli.net/2023/12/07/ohnyKTqPle2d7DH.jpg',
    projecturl: 'https://sonorus.network/',
  },
  {
    projectname: 'Toboto',
    category: 'Tools',
    logourl: 'https://s2.loli.net/2023/12/07/2gYUuyZKN5WbcG1.jpg',
    projecturl: 'https://toboto.com/#/',
  },
  {
    projectname: 'zkHoldem',
    category: 'Games',
    logourl: 'https://s2.loli.net/2023/12/07/ftmlEpuFDJ624eX.jpg',
    projecturl: 'https://zkholdem.xyz/',
  },
];

